<template>
  <div class="index-page">
    <div class="filter">
      <div class="filter-title" @click="toggleFilter">Search Single Women</div>
      <div :class="{ 'hidden-mobile': !showFilter }" @click="openModal">
        <div class="section-filter">
          <div class="section-filter-title">
            Newcomer
          </div>
          <div class="section-filter-content">
            <AppButton class="btn-filter">New girls</AppButton>
            <AppButton class="btn-filter btn-filter--active"
              >All girls</AppButton
            >
          </div>
        </div>
        <div class="section-filter">
          <div class="section-filter-title">
            Status
          </div>
          <div class="section-filter-content">
            <AppButton class="btn-filter btn-filter--active">Online</AppButton>
            <AppButton class="btn-filter">All girls</AppButton>
          </div>
        </div>
        <div class="section-filter">
          <div class="section-filter-title">
            Webcam
          </div>
          <div class="section-filter-content">
            <AppButton class="btn-filter btn-filter--active">Webcam</AppButton>
            <AppButton class="btn-filter">All girls</AppButton>
          </div>
        </div>
        <div class="section-filter">
          <div class="section-filter-title">
            Desire
          </div>
          <div class="section-filter-content jcsb ">
            <AppButton class="btn-filter btn-square btn-filter--active">
              <i class="vc vc-plane"></i>
            </AppButton>
            <AppButton class="btn-filter btn-square btn-filter--active">
              <i class="vc vc-wedding"></i>
            </AppButton>
            <AppButton class="btn-filter btn-square btn-filter--active">
              <i class="vc vc-comment"></i>
            </AppButton>
            <AppButton class="btn-filter btn-square btn-filter--active">
              <i class="vc vc-users"></i>
            </AppButton>
          </div>
        </div>
        <div class="section-filter">
          <div class="section-filter-title">
            Age
          </div>
          <div class="section-filter-content ">
            <Slider
              v-model="age"
              :max="61"
              :min="18"
              @change="onChangeSliderAge"
            />
          </div>
        </div>
        <div class="section-filter">
          <div class="section-filter-title">
            Height
          </div>
          <div class="section-filter-content ">
            <Slider
              v-model="height"
              :max="190"
              :min="105"
              @change="onChangeSliderHeight"
            />
          </div>
        </div>
        <div class="section-filter">
          <div class="section-filter-title">
            Eyes color
          </div>
          <div class="section-filter-content jcsb">
            <AppFakeCheckBoxColor
              :color="color"
              v-for="(color, index) in eyesColor"
              :key="index"
            />
          </div>
        </div>
        <div class="section-filter">
          <div class="section-filter-title">
            Physique
          </div>
          <div class="section-filter-content jcsb">
            <div>
              <AppFakeCheckBox
                :label="physiq.label"
                v-for="(physiq, index) in physique"
                :key="index"
              />
            </div>
          </div>
        </div>
        <div class="section-filter">
          <div class="section-filter-title">
            Hair color
          </div>
          <div class="section-filter-content jcsb">
            <AppFakeCheckBoxColor
              :color="color"
              v-for="(color, index) in hairColor"
              :key="index"
            />
          </div>
          <div class="section-filter-content fdc">
            <div class="form-control">
              <label for="country">
                <span>Country:</span>
              </label>
              <div class="input-group">
                <select disabled name="country" id="country">
                  <option value="">Select</option>
                </select>
                <span class="icon">
                  <i class="vc vc-arrow rotate-90"></i>
                </span>
              </div>
            </div>
            <AppInput label="ID:" placeholder="Enter ID" name="id" readonly />
            <AppInput
              label="Name:"
              placeholder="Enter Name"
              name="name"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <div class="content ">
      <div class="girls-list">
        <div class="col" v-for="user in usersRandom" :key="user.id">
          <AppProfileCard :user="user" />
        </div>
      </div>
      <div class="pagination">
        <ul class="pagination-list" @click="openModal">
          <li>
            <button class="pagination-button">
              <i class="vc vc-arrow rotate-180"></i>
            </button>
          </li>
          <li>
            <button class="pagination-button pagination-button--active">
              1
            </button>
          </li>
          <li>
            <button class="pagination-button">2</button>
          </li>
          <li>
            <button class="pagination-button hidden-mobile">3</button>
          </li>
          <li>
            <button class="pagination-button hidden-tablet">4</button>
          </li>
          <li>
            <button class="pagination-button hidden-tablet">5</button>
          </li>
          <li>
            <button class="pagination-button hidden-tablet">6</button>
          </li>
          <li>
            <button class="pagination-button hidden-desktop">...</button>
          </li>
          <li>
            <button class="pagination-button hidden-mobile">7</button>
          </li>
          <li>
            <button class="pagination-button">8</button>
          </li>
          <li>
            <button class="pagination-button">
              <i class="vc vc-arrow"></i>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import Slider from "@vueform/slider";
import "@vueform/slider/themes/default.css";
import AppFakeCheckBoxColor from "@/components/AppFakeCheckBoxColor";
import AppFakeCheckBox from "@/components/AppFakeCheckBox";
import AppInput from "@/components/AppInput";
import AppProfileCard from "@/components/AppProfileCard";
import openSignUpModalMixin from "@/mixins/openSignUpModalMixin";
import { mapActions, mapGetters } from "vuex";
export default {
	name: "Home",
	data() {
		return {
			showFilter: false,
			age: [18, 61],
			height: [105, 190],
			eyesColor: [
				"#6A2D00",
				"#52CBA7",
				"#A4A4A4",
				"#2BC2E3",
				"#1D69BA",
				"#000000",
				"#EFAE00",
			],
			hairColor: ["#DCEFCC", "#343740", "#6A2D00", "#FFAA00", "#B3BFC6"],
			physique: [
				{ label: "Thin" },
				{ label: "Averange" },
				{ label: "Athletic" },
				{ label: "A few extra pounds" },
			],
		};
	},
	mixins: [openSignUpModalMixin],
	components: {
		AppProfileCard,
		AppInput,
		AppFakeCheckBox,
		AppFakeCheckBoxColor,
		AppButton,
		Slider,
	},
	computed: {
		...mapGetters({
			usersRandom: "usersRandom",
		}),
	},
	methods: {
		...mapActions({
			fetchUsers: "fetchUsers",
		}),
		onChangeSliderAge() {
			this.age = [18, 61];
			this.openModal();
		},
		onChangeSliderHeight() {
			this.height = [105, 190];
			this.openModal();
		},
		toggleFilter() {
			if (window.innerWidth > 768) return;
			this.showFilter = !this.showFilter;
		},
	},
	mounted() {
		this.fetchUsers();
	},
};
</script>
