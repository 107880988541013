<template>
  <transition name="fade">
    <div class="popup" @click.self="closeModal" v-if="modelValue">
      <div class="popup-container">
        <button @click="closeModal" class="btn-close">
          <i class="vc vc-close"></i>
        </button>
        <div class="sign-in-wrap">
          <div class="sign-up--title">Sign Up <span>FREE</span></div>

          <form @submit.prevent="onSubmit">

            <div class="flex-grow">
              <AppInput
                name="name"
                placeholder="John"
                label="First name:"
                required
                prepend-icon="vc-user"
                v-model="form.name"
                @input="validateForm"
                @blur="validateForm"
                :class="{ 'error-field': form.errorNameStatus }"
              />

            <div class="error" v-if="form.errorName">{{ form.errorName }}</div>

              <AppInput
                name="email"
                placeholder="example@email.com"
                label="E-mail:"
                required
                prepend-icon="vc-email"
                type="email"
                v-model="form.email"

                @input="validateForm"
                @blur="validateForm"
                :class="{ 'error-field': form.errorEmailStatus }"
              />

              <div class="error" v-if="form.errorEmail">
                {{ form.errorEmail }}
              </div>

              <div class="form-check" @click="form.checkboxAgreement = !form.checkboxAgreement">
                <label
                  ><input
                    type="checkbox"
                    required="required"
                    name="sign_agree"
                    class="checkbox"

                    :class="{ checked: form.checkboxAgreement }"
                    :checked="form.checkboxAgreementModal"
                    v-model="form.checkboxAgreement"
                  />
                  <span>I agree with License agreement</span>
                </label
                >
              </div>
            </div>

            <div class="error" v-if="!form.checkboxAgreement">
              {{ form.errorCheckbox }}
            </div>

            <div class="form-buttons">
              <AppButton class="btn-green" @click="validateForm" :disabled="
                form.errorEmailStatus ||
                  form.errorEmailStatus ||
                  !form.checkboxAgreement
              ">Create an account</AppButton>

            </div>

          </form>

        </div>
        <div class="copyright">
          2008-2021 © All rights reserved
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppInput from "@/components/AppInput";
import { mapState } from "vuex";
import $ from "jquery";

import axios from "axios";

export default {
	name: "AppSignUpModal",
	props: {
		modelValue: Boolean,
	},
	emits: ["update:modelValue"],
	data() {
		return {
			// new
			form: {
				name: "",
				email: "",
				checkboxAgreement: true,
				errorCheckbox: "This field is required.",

				allError: null,
				validSubmit: false,

				errorNameStatus: false,
				errorEmailStatus: false,
			},
		};
	},
	watch: {
		modelValue() {
			if (this.modelValue) {
				this.setSignUpModal();
			} else {
				this.closeSignUpModal();
			}
		},
	},
	components: { AppInput, AppButton },
	computed: {
		...mapState({
			ip: (state) => state.ip,
		}),
	},
	methods: {
		closeModal() {
			this.$emit("update:modelValue", false); // previously was `this.$emit('input', title)`
		},
		setSignUpModal() {
			document
				.querySelector("body")
				.classList.add("sign-popup", "overflow-hidden");
		},
		closeSignUpModal() {
			document
				.querySelector("body")
				.classList.remove("sign-popup", "overflow-hidden");
		},

		// new
		validateForm: function () {
			if (!this.form.name) {
				this.form.errorNameStatus = true;
				this.form.errorName = "This field is required.";
			} else if (this.form.name.length <= 2) {
				this.form.errorNameStatus = true;
				this.form.errorName = "Please enter at least 3 characters.";
			} else if (this.form.name.length >= 14) {
				this.form.errorNameStatus = true;
				this.form.errorName = "Please enter no more than 15 characters.";
			} else {
				this.form.errorNameStatus = this.form.errorName = false;
			}

			if (!this.form.email) {
				this.form.errorEmailStatus = true;
				this.form.errorEmail = "This field is required.";
			} else if (this.form.email.length <= 5) {
				this.form.errorEmailStatus = true;
				this.form.errorEmail = "Please enter at least 6 characters.";
			} else if (!this.validEmail(this.form.email)) {
				this.form.errorEmailStatus = true;
				this.form.errorEmail = "Please, enter a valid email";
			} else {
				this.form.errorEmailStatus = this.form.errorEmail = false;
			}

			// if (!this.form.allError) {
			//   this.form.allError = "This field is required.";
			// } else {
			//   this.form.errorEmailStatus = this.form.errorEmail = false;
			// }

			// !this.form.allError
			if (this.form.name && this.form.email && this.form.checkboxAgreement) {
				this.form.validSubmit = true;
			} else {
				this.form.validSubmit = false;
			}
		},

		validEmail: function (email) {
			let re =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},

		onSubmit: async function () {
			if (this.form.validSubmit) {
				let formData =
					"name=" +
					this.form.name +
					"&email=" +
					this.form.email +
					"&sign_agree=" +
					this.form.checkboxAgreement;

				let ip = this.ip;

				let oSearchParams = new URL(document.location).searchParams;

				formData +=
					"&ip=" +
					ip +
					"&pid=" +
					oSearchParams.get("pid") +
					"&pid_sid=" +
					oSearchParams.get("sid");

				try {
					let oResp = await axios.post("/api/?action=registration", formData);

					if (oResp.data.error && oResp.data.error !== "") {
						alert(oResp.data.error);
					} else if (oResp.data.loc) {
						setTimeout(function () {
							document.location = oResp.data.loc;
						}, 500);
					}
				} catch (e) {
					console.error(e);
				}
			}
		},
	},
	mounted() {
		if (this.modelValue) {
			this.setSignUpModal();
		} else {
			this.closeSignUpModal();
		}
	},
};
</script>

<style scoped lang="scss">
.btn-close {
  display: none;
  @media (max-width: 768px) {
    width: 26px;
    height: 26px;
    background: rgba(#f0f0f0, 0.6);
    color: #6e6c79;
    display: flex;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    font-size: 13px;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 15px;
    top: 15px;
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  overflow: auto;
  z-index: 999;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 0;
  }
}
.popup-container {
  width: 100%;
  max-width: 600px;
  height: 700px;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  margin: auto;
  @media (max-width: 768px) {
    height: 100%;
    overflow: auto;
    border-radius: 0;
    padding: 15px;
  }
  .copyright {
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.42);
    margin-top: 20px;
  }
}

.sign-in-wrap {
  width: 100%;
  max-width: 270px;
  margin: auto;
  .form-control {
    margin-bottom: 20px;
  }
}

.form-check {
  margin-bottom: 30px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
