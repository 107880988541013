<template>
  <AppHeader />
  <div class="main">
    <router-view :key="$route.params.id" />
  </div>
  <AppFooter />
  <AppSignUpModal v-model="modelDialog" />
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import AppSignUpModal from "@/components/AppSignUpModal";
import { mapActions } from "vuex";

import axios from "axios";

// import $ from "jquery";
export default {
	components: { AppSignUpModal, AppFooter, AppHeader },
	data() {
		return {
			modelDialog: false,
		};
	},
	methods: {
		...mapActions({
			fetchUsers: "fetchUsers",
			getIp: "getIp",
		}),
		sendPidAndSid: async function () {
			if (process.env.NODE_ENV === "development") return;

			let params = new URL(document.location).searchParams;
			let data =
				"pid=" +
				params.get("pid") +
				"&sid=" +
				params.get("sid") +
				"&referer=" +
				document.referrer;

			let options = {
				method: "post",
				url: "/api/?action=send_partner_info",
				data: data,
			};

			try {
				await axios(options);
			} catch (e) {
				console.error(e);

				return e;
			}

			console.log("Success send pid/sid");
		},
	},
	mounted() {
		this.getIp();
		// this.fetchUsers();
		this.sendPidAndSid();
	},
	created() {
		this.emitter.on("openModal", (isOpen) => {
			this.modelDialog = isOpen;
		});
	},
};
</script>
