<template>
  <header class="header">
    <div class="header-content">
      <router-link to="/" class="logo">
          <img src="/images/logo.svg" alt="logo" />
      </router-link>

      <div class="spacer"></div>
      <div class="header-count">
        <span class="blue-text">7043</span> Single Girls
      </div>
      <div class="header-count">
        <span class="green-text">{{ userOnlineCount }}</span> Online Now
      </div>

      <AppButton class="btn-orange btn-large" @click="openModal"
        >Create Account
        <span class="green-label free-label">Free</span>
      </AppButton>

    </div>
  </header>
</template>

<script>
import AppButton from "@/components/AppButton";
import openSignUpModalMixin from "@/mixins/openSignUpModalMixin";
export default {
	name: "AppHeader",
	components: { AppButton },
	computed: {
		userOnlineCount() {
			return Math.floor(Math.random() * (421 - 321 + 1)) + 321;
		},
	},
	mixins: [openSignUpModalMixin],
};
</script>
